import React from 'react';
import javascriptLogo from '../assets/javascript.png';
import htmlLogo from '../assets/html.png';
import cssLogo from '../assets/css.png';
import reactLogo from '../assets/react.png';
import tailwindLogo from '../assets/tailwind.png';
import bootstrapLogo from '../assets/bootstrap-logo.png';
import nodeLogo from '../assets/node.png';
import postgresLogo from '../assets/postgresql_logo.png';
import csharp from '../assets/csharp.png';
import netcore from '../assets/netcore.png';

const Skills = () => {
  const logos = [
    javascriptLogo,
    htmlLogo,
    cssLogo,
    reactLogo,
    tailwindLogo,
    bootstrapLogo,
    nodeLogo,
    csharp,
    netcore,
    postgresLogo,
  ];

  return (
    <div id='skills' className='w-full h-full'>
      <div className='max-w-[1000px] mx-auto px-4 flex flex-col h-full'>
        <h1 className='text-4xl sm:text-7xl text-[#ccd6f6] font-bold mb-[50px] text-center'>
          Skills
        </h1>
        <div className='mx-auto gap-y-10 gap-x-10 grid grid-cols-3 sm:grid-cols-4 md:gap-x-40 '>
          {logos.map((logo, index) => (
            <div key={index}>
              <img
                src={logo}
                alt='pic'
                style={{ width: '50px', height: '50px' }}
              />
            </div>
          ))}
        </div>
      </div>
      <div className='h-[140px]' />
    </div>
  );
};

export default Skills;
