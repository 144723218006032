import React from 'react';
import flashcard from '../assets/flashcard.png';
import welovemovies from '../assets/welovemovies.png';
import recipe from '../assets/recipe-tracking-app.png';
import decoder from '../assets/decoder.png';
import birds from '../assets/birds.png';
import restaurant from '../assets/restaurant-reservation.png';

const Projects = () => {
  const projects = [
    {
      id: 1,
      image: birds,
      title: 'Bird Generator',
      description:
        'Bird Generator is an educational, full-stack application that offers an interactive platform for users to explore, learn about, and randomly generate data on various bird species found in North America.',
      previewLink: 'https://birdgenerator.onrender.com',
      codeLink: 'https://github.com/willthedevelop3r/birds-frontend',
    },
    {
      id: 2,
      image: restaurant,
      title: 'Restaurant Reservation App',
      description:
        'This full-stack application streamlines the reservation and seating process for restaurants, allowing for efficient management of bookings, guest details, and seating statuses through an intuitive dashboard interface.',
      previewLink: 'https://restaurant-reservation-wx3v.onrender.com/dashboard',
      codeLink: 'https://github.com/willthedevelop3r/restaurant-reservation',
    },
    {
      id: 3,
      image: flashcard,
      title: 'Flashcard Study Tool App',
      description:
        'This CRUD application enhances your study experience by enabling you to create and manage flashcards. Each flashcard contains a question with a hidden answer that can be revealed by flipping the card.',
      previewLink: 'https://flashcard-studytool-app.onrender.com/',
      codeLink: 'https://github.com/willthedevelop3r/flashcard-studytool-app',
    },

    {
      id: 4,
      image: welovemovies,
      title: 'We Love Movies App',
      description:
        "This application provides information about movies at various theaters, along with critics' reviews. I was responsible for developing the backend of this project using Node.js, PostgreSQL, Express, and Knex.",
      previewLink: 'https://we-love-movies-pvjb.onrender.com',
      codeLink: 'https://github.com/willthedevelop3r/we-love-movies-backend',
    },
    {
      id: 5,
      image: decoder,
      title: 'Encryption/Decryption App',
      description:
        'This application allows you to encrypt or decrypt your messages using three different methods: Caesar shift, Polybius square, and Substitution cipher. I built the functionality of this application using JavaScript.',
      previewLink:
        'https://willthedevelop3r.github.io/encryption-decryption-app/',
      codeLink: 'https://github.com/willthedevelop3r/encryption-decryption-app',
    },
  ];

  return (
    <div id='projects' className='w-full h-full'>
      <div className='max-w-[1000px] mx-auto px-4 flex flex-col h-full'>
        <h1 className='text-4xl sm:text-7xl text-[#ccd6f6] font-bold mb-10 text-center'>
          Projects
        </h1>
        <div className='flex flex-col justify-center items-center md:grid md:grid-cols-2 gap-y-10'>
          {projects.map(
            ({ id, image, title, description, previewLink, codeLink }) => (
              <div
                key={id}
                className='max-w-md rounded-lg overflow-hidden bg-gray-300'
              >
                <img src={image} alt='image' className='rounded-lg' />
                <div className='px-6 py-4'>
                  <div className='font-bold text-xl mb-2'>{title}</div>
                  <p className='text-gray-700 text-base'>{description}</p>
                </div>
                <div className='flex justify-end px-6 pt-4 pb-2'>
                  {previewLink && (
                    <span className='inline-block bg-blue-600 rounded-full px-3 py-1 text-sm font-semibold text-white mr-2 mb-2'>
                      <a
                        href={previewLink}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Preview
                      </a>
                    </span>
                  )}
                  {codeLink && (
                    <span className='inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2'>
                      <a
                        href={codeLink}
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        Code
                      </a>
                    </span>
                  )}
                </div>
              </div>
            )
          )}
        </div>
      </div>
      <div className='h-[140px]' />
    </div>
  );
};

export default Projects;
