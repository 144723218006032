import React, { useState } from 'react';
import { FaGithub, FaLinkedin } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import resume from '../assets/resume.pdf';
import axios from 'axios';

const Contact = () => {
  const socialIcons = [
    {
      id: 1,
      // text: 'Linkedin',
      icon: <FaLinkedin size={30} />,
      color: 'bg-blue-800 text-gray-300',
      href: 'https://www.linkedin.com/in/williamnguyen773/',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      id: 2,
      // text: 'GitHub',
      icon: <FaGithub size={30} />,
      color: 'bg-[#0f172a] text-gray-300',
      target: '_blank',
      href: 'https://github.com/willthedevelop3r',
      rel: 'noopener noreferrer',
    },
    {
      id: 3,
      // text: 'Email',
      icon: <HiOutlineMail size={30} />,
      color: 'bg-blue-800 text-gray-300',
      href: 'mailto:williamnguyen773@gmail.com',
      // onClick: { copyToClipboard },
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      id: 4,
      // text: 'Resume',
      icon: <BsFillPersonLinesFill size={30} />,
      color: 'bg-[#0f172a] text-gray-300',
      href: resume,
      download: 'resume.pdf',
    },
  ];

  const initialForm = {
    name: '',
    email: '',
    subject: '',
    message: '',
  };

  const [formData, setFormData] = useState(initialForm);

  const handleInputChange = (e) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(process.env.REACT_APP_BACK_URL, formData)
      .then((response) => {
        console.log(formData);
      })
      .catch((error) => {
        console.error('Error sending email:', error);
      })
      .finally(() => {
        setFormData(initialForm);
      });
  };

  return (
    <div id='contact' className='w-full h-full p-4'>
      <div className='max-w-[1000px] mx-auto px-4 flex flex-col h-full'>
        <div>
          <h1 className=' text-4xl sm:text-7xl text-[#ccd6f6] font-bold text-center'>
            Contact
          </h1>
        </div>
        <div>
          <p className='text-gray-300 py-4 text-center'>
            * Submit the form below or email me at williamnguyen773@gmail.com
          </p>
        </div>
        <input
          className='p-2 bg-[#ccd6f6]'
          type='text'
          placeholder='Your Name'
          name='name'
          value={formData.name}
          onChange={handleInputChange}
          required
        />
        <input
          className='mt-4 p-2 bg-[#ccd6f6]'
          type='email'
          placeholder='Your Email'
          name='email'
          value={formData.email}
          onChange={handleInputChange}
          required
        />
        <input
          className='mt-4 p-2 bg-[#ccd6f6]'
          type='text'
          placeholder='Subject'
          name='subject'
          value={formData.subject}
          onChange={handleInputChange}
          required
        />
        <textarea
          className='bg-[#ccd6f6] mt-4 p-2'
          name='message'
          rows='10'
          placeholder='Message'
          value={formData.message}
          onChange={handleInputChange}
          required
        />
        <button
          className='text-white border-2 hover:bg-blue-600 px-4 py-3 my-8 mx-auto flext items-center'
          onClick={handleSubmit}
        >
          Let's Collaborate
        </button>

        <div className='mx-auto grid grid-cols-4 gap-10 md:gap-x-35 pt-6 lg:hidden'>
          {socialIcons.map(({ id, text, icon, color, href }) => (
            <div key={id} className='flex flex-col items-center'>
              <a
                className={`flex justify-center items-center ${color} rounded-full w-12 h-12`}
                href={href}
                target='_blank'
                rel='noopener noreferrer'
                download='resume.pdf'
              >
                {icon}
              </a>
              {/* <p className='text-white text-center mt-2'>{text}</p> */}
            </div>
          ))}
        </div>
      </div>
      {/* <div className='h-[160px]' /> */}
    </div>
  );
};

export default Contact;
