import React from 'react';
import me from '../assets/me.png';

const Home = () => {
  return (
    <div id='home' className='w-full h-screen flex items-center justify-center'>
      <div className='max-w-[1000px] mx-auto px-4 flex flex-col items-center sm:items-start'>
        <div className='flex flex-col'>
          <img
            src={me}
            alt='me'
            className='block sm:hidden w-[45%] h-[45%] mx-auto mb-4 rounded-xl'
          />
          <p className='text-gray-300 text-lg text-center sm:text-left'>
            Hi, my name is
          </p>
          <h1 className='text-4xl sm:text-7xl text-[#ccd6f6] font-bold text-center sm:text-left'>
            Billy
          </h1>
          <h2 className='text-center text-lg sm:text-7xl text-[#8892b0] font-bold sm:text-left'>
            I'm a Full Stack Developer.
          </h2>
          <p className='text-gray-300 text-lg mt-3 text-center sm:text-left'>
            I am based in St. Louis, MO and enjoy coding and building
            applications. I enjoy solving problems and translating ideas into
            functional and visually appealing applications. Feel free to explore
            my portfolio and get in touch if you have any exciting projects or
            opportunities in mind. Let's bring your ideas to life and make an
            impact together.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Home;
