import React, { useState } from 'react';
import { FaBars, FaTimes, FaGithub, FaLinkedin } from 'react-icons/fa';
import { HiOutlineMail } from 'react-icons/hi';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import me from '../assets/me.png';
import resume from '../assets/resume.pdf';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);

  // const copyToClipboard = async () => {
  //   navigator.clipboard.writeText(email);
  //   // Optionally, you can show a notification or perform any other desired action
  // };

  const menuItems = [
    { id: 1, text: 'Home', href: '#home' },
    { id: 2, text: 'About', href: '#about' },
    { id: 3, text: 'Projects', href: '#projects' },
    { id: 4, text: 'Skills', href: '#skills' },
    { id: 5, text: 'Contact', href: '#contact' },
  ];

  const socialIcons = [
    {
      id: 1,
      text: 'Linkedin',
      icon: <FaLinkedin size={30} />,
      color: 'bg-blue-800',
      href: 'https://www.linkedin.com/in/williamnguyen773/',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      id: 2,
      text: 'Github',
      icon: <FaGithub size={30} />,
      color: 'bg-[#0f172a] text-gray-300',
      href: 'https://github.com/willthedevelop3r',
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      id: 3,
      text: 'Email',
      icon: <HiOutlineMail size={30} />,
      color: 'bg-blue-800 text-gray-300',
      href: 'mailto:williamnguyen773@gmail.com',
      // onClick: { copyToClipboard },
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      id: 4,
      text: 'Resume',
      icon: <BsFillPersonLinesFill size={30} />,
      color: 'bg-[#0f172a] text-gray-300',
      href: resume,
      download: 'resume.pdf',
    },
  ];

  return (
    <div className='fixed w-full h-[80px] flex justify-between items-center px-4 text-gray-300'>
      <div>
        <img src={me} alt='me' style={{ width: '35px', height: '35px' }} />
      </div>

      {/* menu */}
      <ul className='hidden md:flex'>
        {menuItems.map((item) => (
          <li key={item.id}>
            <a href={item.href}>{item.text}</a>
          </li>
        ))}
      </ul>

      {/* hamburger */}
      <div onClick={handleClick} className='md:hidden z-10'>
        {!nav ? (
          <FaBars className='cursor-pointer' size={20} />
        ) : (
          <FaTimes className='cursor-pointer' />
        )}
      </div>

      {/* mobile menu*/}
      <ul
        className={
          !nav
            ? 'hidden'
            : 'absolute top-0 left-0 w-full h-screen bg-gray-900 flex flex-col justify-center items-center'
        }
      >
        {menuItems.map((item) => (
          <li className='py-3 text-xl' key={item.id}>
            <a href={item.href} onClick={handleClick}>
              {item.text}
            </a>
          </li>
        ))}
      </ul>

      {/* socialIcons */}
      <div className='hidden lg:flex fixed flex-col top-[35%] left-0'>
        <ul>
          {socialIcons.map(({ id, text, icon, color, href }) => (
            <li
              key={id}
              className={`w-[120px] h-[50px] flex justify-between items-center ${color} ml-[-70px] hover:ml-[-10px] duration-300`}
            >
              <a
                className='flex justify-between items-center w-full'
                href={href}
                target='_blank'
                rel='noopener noreferrer'
                download='resume.pdf'
              >
                {text} {icon}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
