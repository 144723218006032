import React from 'react';
import me from '../assets/me.png';

const About = () => {
  return (
    <div
      id='about'
      className='w-full h-screen flex items-center justify-center mb-[100px]'
    >
      <div className='max-w-[1000px] mx-auto px-4 flex flex-col items-center sm:flex-row'>
        <div className='flex flex-col '>
          <h1 className='text-4xl sm:text-7xl text-[#ccd6f6] font-bold mb-3 text-center '>
            About
          </h1>
          <p className='text-gray-300 text-lg mt-3 max-w-[800px] text-center'>
            I'm William, but please feel free to call me Billy. I'm passionate
            about coding and building applications and find immense joy in
            creating functional and user-friendly digital experiences. Prior to
            becoming a developer, I worked as an ink technician in the printing
            industry, where I learned the significance of collaboration and
            teamwork for driving a company's growth and success. Working as an
            ink technician has also given me a keen eye for detail. I have
            completed a 5-month full-time Immersive Software Engineer program at
            Thinkful which was a wonderful experience. During the program, I
            gained hands-on experience working with the PERN stack. Furthermore,
            I also have hands-on experience working with C# (.NET Core, ASP.NET
            Core, Blazor, Web API, MVC). As an engineer, I'm dedicated to
            continuous learning and skill development. Beyond coding, I take
            pleasure in various activities like engaging in communities,
            reading, immersing myself in nature, playing games, listening to
            music, watching movies, doing volunteer work, and cherishing quality
            time with family and friends.
          </p>
        </div>
        <div>
          <img
            src={me}
            alt='me'
            className='hidden md:block md:rounded-xl md:mx-auto  md:w-2/3 md:h-2/3'
          />
        </div>
      </div>
    </div>
  );
};

export default About;
